var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Gallery"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"my-lg-2 pa-0",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('div',[_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.filter($event)}}},[_vm._v(" All ")]),_vm._l((_vm.categories),function(category){return _c('v-btn',{key:category.id,staticClass:"mr-1 mb-1",class:{
              'indigo lighten-2': category.grandalliance === 'Order',
              'deep-orange lighten-1': category.grandalliance === 'Chaos',
              'light-blue lighten-2': category.grandalliance === 'Death',
              'green lighten-2': category.grandalliance === 'Destruction',
            },on:{"click":function($event){return _vm.filter($event)}}},[_vm._v(" "+_vm._s(category.alliance)+" ")])})],2):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"open-on-click":"","allow-overflow":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":"100%","height":"80px","color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" fas fa-clipboard-list ")]),_vm._v(" Armylist "),_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-caret-down ")])],1)]}}],null,false,4130503167)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.filter($event)}}},[_c('v-list-item-title',[_vm._v("All")])],1),_vm._l((_vm.categories),function(category){return _c('v-list-item',{key:category.id,on:{"click":function($event){return _vm.filter($event)}}},[_c('v-list-item-title',[_vm._v(_vm._s(category.alliance))])],1)})],2)],1):_vm._e()],1),_vm._l((_vm.filteredProjects),function(project){return _c('v-col',{key:project.id,staticClass:"d-flex child-flex pa-0",attrs:{"cols":"6","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-card',{attrs:{"tile":"","outlined":""},on:{"click":function($event){return _vm.zoomOpen(project)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":require(("../../../src/assets/images/gallery/" + (project.image))),"alt":project.name,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"\n                    d-flex\n                    transition-fast-in-fast-out\n                    v-card--reveal\n                    white--text\n                    gallery_photos_card_hover_content\n                  ",staticStyle:{"height":"25%"},attrs:{"width":"100%","bottom":""}},[_c('h5',[_vm._v(_vm._s(project.name))])]):_vm._e()])],1)]}}],null,true)})],1)],1)}),_c('v-dialog',{attrs:{"max-width":"800","max-height":"1600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',[_c('v-carousel',{attrs:{"width":"auto","height":"auto"},model:{value:(_vm.selectedModel),callback:function ($$v) {_vm.selectedModel=$$v},expression:"selectedModel"}},_vm._l((_vm.selectedCarousel),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item}})}),1),_c('v-list',{attrs:{"color":"primary","two-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"gallery_photos_items_text"},[_vm._v(" Painted by: "+_vm._s(_vm.projectInformation.painter_name)+" ")]),_c('v-list-item-subtitle',{staticClass:"gallery_photos_items_text"},[_vm._v(" Model: "+_vm._s(_vm.projectInformation.name)+" ")])],1),_c('v-list-item-action',[(_vm.projectInformation.painter_instagram)?_c('v-btn',{staticClass:"gallery_photos_items_text",attrs:{"href":_vm.projectInformation.painter_instagram,"icon":"","target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-instagram ")])],1):_vm._e()],1)],1)],1)],1)]),_c('BackToTop')],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }